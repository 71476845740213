<template>
<div>
  <div class="row justify-content-center my-5" id="buttons">
    <b-button-toolbar key-nav aria-label="Opciones">
      <b-button-group class="mx-1">
        <b-button variant="secondary" @click="printReport()">
          <b-icon icon="printer-fill"/> Imprimir
        </b-button>
        <b-button variant="primary" :to="{name:'home'}">
          <b-icon icon="house-door-fill"/> Inicio
        </b-button>
        <b-button variant="light" @click="goBack()">
          <b-icon icon="arrow-return-left"/> Regresar
        </b-button>
      </b-button-group>
    </b-button-toolbar>
  </div>
  <b-container id="print" v-if="ready">
    <b-table-simple hover small striped stacked>
      <b-tbody>
        <b-tr>
          <b-th class="text-white text-center bg-dark">Inspección fiscal: {{id}}</b-th>
        </b-tr>
        <b-table-simple hover small striped stacked caption-top bordered>
          <b-tbody>
            <b-tr>
                <b-td stacked-heading="Cliente / Solicitante" class="text-center">
                    {{taxInspection.clients.name}}
                </b-td>
                <b-td stacked-heading="Responsable del cliente" class="text-center">
                    {{taxInspection.client_responsible}}
                </b-td>
            </b-tr>
          </b-tbody>
          <b-tbody>
            <b-tr>
              <b-th class="text-white text-center bg-dark">Etapa de recolección</b-th>
            </b-tr>
            <b-tr>
                <b-td stacked-heading="Lugar de la recolección:" class="text-center">
                    {{taxInspection.recolection_place}}
                </b-td>
                <b-td stacked-heading="Nombre de quien recibió:" class="text-center">
                    {{taxInspection.recolection_by }}
                </b-td>
                <b-td stacked-heading="Fecha de recolección:" class="text-center">
                    {{moment(taxInspection.recolection_dated_at).utc().format('DD/MM/YY HH:mm a')}}
                </b-td>
                <b-td stacked-heading="Comentarios de recolección:" class="text-center">
                    {{taxInspection.recolection_comments}}
                </b-td>
            </b-tr>
            <b-tr>
              <b-col v-for="photo in taxInspection.recolection_photos" :key="photo.uid">
                <b-card :img-src="photo.url"
                    :img-alt="photo.name"
                    img-top
                    style="max-width: 20rem;"
                    class="mx-1">
                </b-card>
              </b-col>
            </b-tr>
          </b-tbody>
          <b-tbody>
            <b-tr>
              <b-th class="text-white text-center bg-dark">Etapa de recepción</b-th>
            </b-tr>
            <b-tr>
                <b-td stacked-heading="Vigilante:" class="text-center">
                    {{taxInspection.reception_vigilant}}
                </b-td>
                <b-td stacked-heading="Técnico de Operaciones:" class="text-center">
                    {{taxInspection.reception_technical_operation }}
                </b-td>
                <b-td stacked-heading="Fecha de recepción:" class="text-center">
                    {{moment(taxInspection.reception_dated_at).utc().format('DD/MM/YY HH:mm a')}}
                </b-td>
                <b-td stacked-heading="Comentarios:" class="text-center">
                    {{taxInspection.recolection_comments}}
                </b-td>
            </b-tr>
            <b-tr>
              <b-col v-for="photo in taxInspection.reception_photos" :key="photo.uid">
                <b-card :img-src="photo.url"
                    :img-alt="photo.name"
                    img-top
                    style="max-width: 20rem;"
                    class="mx-1">
                </b-card>
              </b-col>
            </b-tr>
          </b-tbody>
          <b-tbody>
            <b-tr>
              <b-th class="text-white text-center bg-dark">Etapa de Almacenamiento</b-th>
            </b-tr>
            <b-tr>
                <b-td stacked-heading="Responsable:" class="text-center">
                    {{taxInspection.storage_responsible}}
                </b-td>
                <b-td stacked-heading="Técnico de Operaciones:" class="text-center">
                    {{taxInspection.storage_technical_operation }}
                </b-td>
                <b-td stacked-heading="Fecha de almacenamiento:" class="text-center">
                    {{moment(taxInspection.storage_dated_at).utc().format('DD/MM/YY HH:mm a')}}
                </b-td>
                <b-td stacked-heading="Comentarios:" class="text-center">
                    {{taxInspection.storage_comments}}
                </b-td>
            </b-tr>
            <b-tr>
              <b-col v-for="photo in taxInspection.storage_photos" :key="photo.uid">
                <b-card :img-src="photo.url"
                    :img-alt="photo.name"
                    img-top
                    style="max-width: 20rem;"
                    class="mx-1">
                </b-card>
              </b-col>
            </b-tr>
          </b-tbody>
          <b-tbody>
            <b-tr>
              <b-th class="text-white text-center bg-dark">Etapa de Disposición</b-th>
            </b-tr>
            <b-tr>
                <b-td stacked-heading="Responsable:" class="text-center">
                    {{taxInspection.disposal_responsible}}
                </b-td>
                <b-td stacked-heading="Proceso de destrucción:" class="text-center">
                    {{taxInspection.disposal_process }}
                </b-td>
                <b-td stacked-heading="Fecha de destrucción:" class="text-center">
                    {{moment(taxInspection.disposal_dated_at).utc().format('DD/MM/YY HH:mm a')}}
                </b-td>
                <b-td stacked-heading="Comentarios:" class="text-center">
                    {{taxInspection.disposal_comments}}
                </b-td>
            </b-tr>
            <b-tr>
              <b-col v-for="photo in taxInspection.disposal_photos" :key="photo.uid">
                <b-card :img-src="photo.url"
                    :img-alt="photo.name"
                    img-top
                    style="max-width: 20rem;"
                    class="mx-1">
                </b-card>
              </b-col>
            </b-tr>
          </b-tbody>
          <b-tbody>
            <b-tr>
              <b-th class="text-white text-center bg-dark">Etapa de Coprocesamiento</b-th>
            </b-tr>
            <b-tr>
                <b-td stacked-heading="Responsable de Geocycle para realizar el proceso:" class="text-center">
                    {{taxInspection.coprocess_responsible}}
                </b-td>
                <b-td stacked-heading="Punto de Alimentación de Coprocesamiento" class="text-center">
                    {{taxInspection.coprocess_storage_point }}
                </b-td>
                <b-td stacked-heading="Fecha:" class="text-center">
                    {{moment(taxInspection.coprocess_dated_at).utc().format('DD/MM/YY HH:mm a')}}
                </b-td>
                <b-td stacked-heading="Comentarios:" class="text-center">
                    {{taxInspection.coprocess_comments}}
                </b-td>
            </b-tr>
            <b-tr>
              <b-col v-for="photo in taxInspection.coprocess_photos" :key="photo.uid">
                <b-card :img-src="photo.url"
                    :img-alt="photo.name"
                    img-top
                    style="max-width: 20rem;"
                    class="mx-1">
                </b-card>
              </b-col>
            </b-tr>
          </b-tbody>
          <!-- <b-tbody>
            <b-tr>
                <b-td stacked-heading="Tipo de Manejo" class="text-center">
                    {{visualInspection.dischargeMaterials.disposalTypes.name}}
                </b-td>
            </b-tr>
          </b-tbody> -->
          <!-- <b-tbody>
            <b-tr>
                <b-td stacked-heading="Turno de Inspección Visual" class="text-center">
                    {{visualInspection.shifts.name}}
                </b-td>
                <b-td stacked-heading="Responsable de la validación" class="text-center">
                    {{visualInspection.users.name}}
                </b-td>
                <b-td stacked-heading="Fecha de Recepción" class="text-center">
                    {{moment(visualInspection.received_at).utc().format('DD/MM/YY HH:mm')}}
                </b-td>
                <b-td stacked-heading="Fecha de Inspección" class="text-center">
                    {{moment(visualInspection.inspected_at).utc().format('DD/MM/YY HH:mm')}}
                </b-td>
                <b-td stacked-heading="Estatus" class="text-center">
                    <span v-if="visualInspection.status"> Aprobado</span>
                    <span v-else> Pendiente</span>
                </b-td>
                <b-td stacked-heading="Observaciones">
                    {{visualInspection.text}}
                </b-td>
            </b-tr>
          </b-tbody> -->
        </b-table-simple>
      </b-tbody>
    </b-table-simple>
  </b-container>
</div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'taxInspectionprint',
  mixins: [fractalMixin],
  data() {
    return {
      id: this.$route.params.id,
      taxInspection: {},
    }
  },
  computed: {
    ready() {
      return Object.keys(this.taxInspection).length > 0
    },
  },
  methods: {
    printReport() {
      window.print()
    },
    goBack() {
      this.$router.go(-1)
    },
    async retrieveTaxInspection() {
      await this.GetResourcev2(`fiscalInspections/${this.id}`)
        .then(response => {
          this.taxInspection = response.data
        })
    },
  },
  async mounted() {
    await this.retrieveTaxInspection()
  },
}
</script>

<style scoped>
  @media print {
    /* All your print styles go here */
    #buttons { display: none !important; }
  }
</style>
